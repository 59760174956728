<template>
  <information-app-modal
    v-model="model"
    button-title="Continue"
    @close="closeModal"
    :icon="ICON_TYPE.WARNING_YELLOW"
  >
    <h1 class="title">{{ $t('BankPaymentDisabled.HeadingText').value }}</h1>

    <p>{{ $t('BankPaymentDisabled.ParagraphText').value }}</p>

    <template #footer>
      <AppButton @click="closeModal">
        {{ $t('BankPaymentDisabled.ButtonGotIt').value }}
      </AppButton>
      <!-- Keep it, for now -->
    </template>
  </information-app-modal>
</template>

<script>
import { useI18nStore } from '@galileo/stores'
import { ref } from '@vue/composition-api'
import { useRouter } from '@galileo/composables/useRouter'

import InformationAppModal, {
  ICON_TYPE,
} from '@galileo/components/InformationAppModal/InformationAppModal'

import { AppButton, useVModel } from '@oen.web.vue2/ui'

export default {
  name: 'BankPaymentDisabled',
  components: {
    AppButton,
    InformationAppModal,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['input'],
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const { model } = useVModel(props, emit)

    const closeModal = () => {
      model.value = false
    }

    return {
      model,
      $t,
      closeModal,
      ICON_TYPE,
    }
  },
}
</script>

<style scoped>
p {
  @apply text-base;
}
</style>
